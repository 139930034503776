.App {
	text-align: center;
	max-width: 500px;
	margin: 0 auto;
	font-family: "Hero New";
	overflow-y: hidden;
	overflow-x: hidden;
}

.receipt-input {
	padding: 12px 8px;
	background: white;
	border: 1px solid #DDDDDD;
	border-radius: 5px;
	font-size: 14px;
	width: 100%;
}

.receipt-label{
	font-weight: bold;
	color: #172938;
	font-size: 14px;
	width: 100%;
	display: block; 
	margin-bottom: 4px;
	margin-top: 10px; 
	text-align: start;
}

.landing-btn {
	height: 40px;
	font-size: 17px;
	width: 90%;
	background-color: #000f9f;
	border: none;
	color: white;
	font-weight: 500;
}

.landing-btn:disabled {
	background-color: #C0C0C0;
	color: white;
}

.reward-card {
	border: 1px solid #ccc;
	border-radius: 10px;
	padding: 15px;
	margin: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 1px 16px 0px #0000001a;
}

.image-container img {
	max-width: 100%;
	height: auto;
	border-radius: 5px;
	margin-bottom: 10px;
}

.details-container {
	text-align: center;
	margin-bottom: 15px;
}

.additional-details {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.date-info {
	flex-grow: 1;
}

.action-buttons button {
	background-color: #3498db;
	color: #fff;
	padding: 8px 15px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	/* margin-right: 10px; */
}

.x-scrolling {
	overflow-x: scroll;
}

.reward {
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
	border-radius: 12px;
	width: 38vw;
	height: 125px;
	padding: 8px;
	max-height: 220px;
	display: grid;
	place-items: center;
}

.address-card {
	box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.1);
	background-color: white !important;
	border-radius: 10px;
	padding-left: 16px;
	position: relative;
	padding-top: 10px;
}

.edit-address-btn {
	margin-left: auto;
	width: 65%;
	font-size: 14px;
	background-color: #e81b2c !important;
	height: 4vh;
	border-bottom-right-radius: 10px;
	border-top-left-radius: 15px;
	/* position: absolute; */
	/* right: 0; */
	/* bottom: 0; */
	color: white;
}

.delete-address-btn {
	width: 26px;
	background-color: #e81b2c !important;
	height: 26px;
	border-radius: 50px;
	position: absolute;
	right: 10px;
	top: 10px;
	color: white;
}

.pointer-img {
	position: absolute;
	/* transform: translateY(100%); */
	top: 40%;
	z-index: 50;
}

canvas {
	position: relative;
	z-index: 0;
}

/* YourComponent.css */

.carousel-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.carousel-item {
	display: flex;
	justify-content: space-around;
	margin-bottom: 20px;
}

.carousel-content {
	text-align: center;
	margin: auto;
}

.carousel-image {
	width: 100%;
	margin-bottom: 10px;
	margin: auto;
}

.carousel-text {
	display: block;
	font-size: 30px;
	color: #ffffff;
	/* Adjust the color as needed */
	text-align: left;
	margin-left: 40px;
	position: absolute;
	margin-top: -70px;
	font-family: "Gotham Serif";
}

.modal-header {
	background-image: url("../src/assets/Frame.png");
	background-size: cover;
	background-position: center;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -24px;
	margin-top: -20px;
	margin-right: -24px;
	border-radius: 8px;
}

.ant-carousel .slick-dots li button {
	background-color: black;
	background: black;
	border-radius: 50%;
	width: 8px;
	height: 8px;
}

.ant-carousel .slick-dots li.slick-active button {
	background-color: #E81B2C;
	background: #E81B2C;
	border-radius: 50%;
}

.ant-form-item .ant-form-item-explain-error {
	color: white;
}

.phoneAddress .ant-form-item .ant-form-item-explain-error {
	color: red;
}

.oos-text {
	background-color: #909090;
	color: white;
	font-weight: 600;
	border-radius: 8px;
	position: absolute;
	top: 35%;
	left: 15%;
	width: 60%;
	padding: 4px;
	opacity: .90;
}

.phone-input-filled::placeholder {
	color: black;
}

.signup-text {
	margin: 0;
	margin-top: 20px;
	color: white;
	font-size: 16px;
	font-weight: 500;
}

.state-form {
	text-align: left;
	background-color: white;
	height: 46px;
	border: 2px solid grey;
	border-radius: 6px;
}

.home-header {
	color: #29328C;
	font-family: "Mitr";
	font-size: 20px;
	font-weight: 600;
}

.otp-input {
	border: none;
	border-bottom: 4px solid #9c9c9c;
	width: 2.5rem !important;
	height: 3rem;
	text-align: center;
	background-color: transparent;
	color: white;
	font-size: 20px;
	font-weight: bold;
	outline: none;
	transition: border-bottom-color 0.3s; 
}
  
.otp-input:focus {
	border-bottom-color: #29328C;
}